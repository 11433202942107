import React from "react";

import ContactForm from "../components/ContactForm";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_contact.scss";
import ogImage from "../images/OG_Image.png";

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact"
      ogImage={ogImage}
      keywords={["contact", "Flowspring", "we love hearing from you"]}
      description="Contact us at Flowspring - we love hearing from you"
    />
    <ContactForm />
  </Layout>
);

export default ContactPage;
