import React, { useState, useEffect } from "react";

const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSubmitted(false);
    }, 2000);
  });

  const handleContact = e => {
    e.preventDefault();
    fetch(
      "https://hhx3pthj85.execute-api.us-west-2.amazonaws.com/prod/handleContactUs",
      {
        method: "POST",
        body: JSON.stringify({
          email: e.target.email.value,
          subject: e.target.subject.value,
          body: e.target.message.value,
          phone: e.target.phone.value,
        }),
      },
    )
      .then(response => response.json())
      .then(() => { setIsSubmitted(true); });
  };
  return (
    <>
      {isSubmitted && (
        <div id="alert">Message Received - Thanks for contacting us!</div>
      )}
      <div id="formContainer">
        <div id="contactHeader">
          How can we help?
          <div id="subHeader">* Required Information</div>
        </div>
        <form onSubmit={handleContact} id="contactUsForm" method="post">
          <input
            type="text"
            tabIndex="-1"
            autoComplete="off"
            name="phone"
            style={{ position: "absolute", top: -1000, left: -10000 }}
          />
          <label>Email*</label>
          <input type="text" name="email" autoComplete="email" />
          <label>Subject</label>
          <input type="text" name="subject" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="SUBMIT" />
        </form>
      </div>
    </>
  );
};

export default ContactForm;
